import React from "react"
import { graphql } from "gatsby"
import { Smartphone, User, CreditCard } from "react-feather"
import { PrimaryAlternativeBtn, Container } from "components/library"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { SupportCard, SupportCardProps } from "components/pages/support/support-card"

const cards: SupportCardProps[] = [
	{
		heading: "common.words.faq",
		description: "support-page.options.1.desc",
		ctaText: "common.cta.learn-more",
		link: "/faqs/",
		icon: CreditCard,
	},
	{
		heading: "common.words.technical-support",
		description: "support-page.options.2.desc",
		ctaText: "common.words.contact-us",
		link: "/contact-us/",
		icon: User,
	},
	{
		heading: "common.words.demo",
		description: "support-page.options.3.desc",
		ctaText: "common.cta.demo",
		link: "",
		icon: Smartphone,
		ctaOnClick: () => window.requestADemo(),
	},
]

const SupportPage = () => {
	const { t } = useTranslation()

	return (
		<main className="bg-support-mobile bg-cover lg:bg-support-desktop bg-no-repeat -mt-28 sm:-mt-36">
			<Container className="flex flex-col pt-40 items-center sm:pt-48 pb-24 gap-20 sm:gap-40">
				<div className="flex flex-col gap-4 text-neutral items-center">
					<h1 className="text-5xl">{t("support-page.heading")}</h1>

					<p className="text-xl">{t("support-page.desc")}</p>
				</div>

				<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
					{cards.map((card) => {
						return <SupportCard key={card.heading} {...card} />
					})}
				</ul>

				<div className="flex flex-col sm:flex-row justify-between gap-6 w-full max-w-3xl rounded-md bg-gradient-to-r from-[#305CBD] to-[#F8CCA9] py-8 px-12 text-white items-center">
					<div className="flex flex-col gap-1 text-center sm:text-left">
						<h2 className="text-2xl">
							{t("support-page.need-somthing-else.title")}
						</h2>
						<p>{t("support-page.need-somthing-else.desc")}</p>
					</div>

					<Link to="/contact-us/" className="w-full sm:max-w-max">
						<PrimaryAlternativeBtn className="md:text-lg ring-theme-grey w-full text-neutral-900 font-normal">
							{t("common.words.contact-us")}
						</PrimaryAlternativeBtn>
					</Link>
				</div>
			</Container>
		</main>
	)
}

export default SupportPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
