import React, { MouseEvent } from "react"
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { ArrowRight, Icon } from 'react-feather'
import { TranslationKey } from 'types/translation'

type P = {
    link: string
    heading: TranslationKey
    description: TranslationKey
    ctaText: TranslationKey
    icon: Icon
    ctaOnClick?: (e: MouseEvent<HTMLAnchorElement>) => void
}

export type SupportCardProps = P

export const SupportCard = ({ link, heading, description, ctaText, ctaOnClick, icon }: P) => {
    const IconComponent = icon
    const { t } = useTranslation()

    return (
        <li className="bg-neutral flex flex-col w-full sm:max-w-max gap-4 rounded-md p-6 drop-shadow-md">
            <IconComponent size={32} className="text-blue-500" />

            <p className="text-neutral-900 font-semibold text-xl">
                {t(heading) as string}
            </p>

            <p className="text-neutral-500 grow">
                {t(description) as string}
            </p>

            <Link to={link} onClick={ctaOnClick} className="font-bold text-blue-700 flex items-center gap-2">
                {t(ctaText) as string} <ArrowRight size={16} />
            </Link>
        </li>
    )
}
